<template>
    <!--begin::Toolbar-->
    <div
        id="kt_toolbar"
        class="toolbar">
        <!--begin::Container-->
        <div
            id="kt_toolbar_container"
            :class="{
                'container-fluid': toolbarWidthFluid,
                'container-xxl': !toolbarWidthFluid,
            }"
            class="d-flex flex-stack">
            <PageTitle/>

            <!--begin::Actions-->
            <div class="d-flex align-items-center py-1">
                <!--begin::Wrapper-->
                <div class="me-4">
                    <!--begin::Menu-->
                    <a
                        href="#"
                        class="btn btn-sm btn-flex btn-light btn-active-primary fw-bold"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end">
                        <KTIcon
                            icon-name="filter"
                            icon-class="fs-5 text-gray-500 me-1"/>
                        Filter
                    </a>
                    <!--end::Menu-->
                </div>
                <!--end::Wrapper-->

                <!--begin::Button-->
                <a
                    id="kt_toolbar_primary_button"
                    href="#"
                    class="btn btn-sm btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_create_app">
                    Create
                </a>
                <!--end::Button-->
            </div>
            <!--end::Actions-->
        </div>
    <!--end::Container-->
    </div>
    <!--end::Toolbar-->
</template>

<script lang="ts">
import { getAssetPath } from "@/helpers/assets"
import { defineComponent } from "vue"
import PageTitle from "@/layouts/components/PageTitle.vue"
import { toolbarWidthFluid } from "@/layouts/config/helper"
import KTIcon from "@/helpers/kt-icon/KTIcon.vue"

export default defineComponent({
    name: `KToolbar`,
    components: {
        KTIcon,
        PageTitle
    },
    setup() {
        return {
            toolbarWidthFluid,
            getAssetPath
        }
    }
})
</script>
